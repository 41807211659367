import { gql } from "@apollo/client";
export const getProductsQuery = gql`
  query getProducts($voucherCode: String, $buildingCode: String) {
    classMethods {
      Product {
        getProducts(voucherCode: $voucherCode, buildingCode: $buildingCode) {
          id
          name
          description
          active
          value
          rateProfile
          deviceLimit
          type
        }
      }
    }
  }
`;

export function getProductsResult({ data }) {
  return data?.classMethods?.Product?.getProducts || [];
}
