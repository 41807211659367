import React, { Fragment } from "react";
import { useState } from "react";
import DataHelper from "../utils/data-helper";
import UpdateDevice from "./device/update-device";
import RemoveDevice from "./device/remove-device";
import disconnectIcon from '../../images/icons/disconnected.svg'
import "../../style/custom/device-table.scss"

const DeviceTable = (props) => {
    const { devices, hasActivePlan } = props;
    const [deviceToUpdate, setDeviceToUpdate] = useState(undefined);
    const [deviceToRemove, setDeviceToRemove] = useState(undefined);

    return (
      <Fragment>
        {deviceToUpdate && (
          <UpdateDevice device={deviceToUpdate} onClose={async () => setDeviceToUpdate(undefined)} />
        )}
        {deviceToRemove && (
          <RemoveDevice device={deviceToRemove} onClose={async () => setDeviceToRemove(undefined)} />
        )}
        <table className="device-table">
          <thead>
            <tr>
              <th>Device</th>
              <th>Status</th>
              <th>Mac Address</th>
              <th>IP Address</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!hasActivePlan && (
              <tr className="device-table-empty-row">
                <td colSpan={5}>
                  <img
                    src={disconnectIcon}
                    alt="disconnected-icon"
                  />
                  <p>You are disconnected</p>
                </td>
              </tr>
            )}
            {hasActivePlan && devices.length === 0 && (
              <tr className="device-table-empty-row">
                <td colSpan={5}>
                  <img
                    src="https://cdn.vostro.app/cdn/hotspots/scape/icon_no device outline.svg"
                    alt="no-device-icon"
                  />
                  <p>No Device</p>
                </td>
              </tr>
            )}
            {hasActivePlan && devices.map(({node}, node_index) => {
              console.log(node.disabled);
              return (
                <tr key={node_index}>
                  <td className="device-table-device">
                      <i
                        className={DataHelper.getDeviceIcon(node.manufacturer)}
                      />
                      {node.hostname || node.manufacturer || ""}
                  </td>
                  <td className="device-table-device">
                    {node.disabled ? 
                      <i
                        className="fas fa-ban" id="device-disabled-icon" title="disabled"
                      />
                      : 
                      <i
                        className="fas fa-circle" id="device-enabled-icon" title="enabled"
                      />
                    }
                  </td>
                  <td className="device-table-device">{node.macAddress}</td>
                  <td className="device-table-device">{node.ip4 || node.ip6}</td>
                  <td className="text-center device-table-action">
                    <i onClick={() => setDeviceToUpdate(node)} title="edit">
                      <img
                        src="https://cdn.vostro.app/cdn/hotspots/scape/Icon awesome-pencil-alt.svg"
                        alt="edit-icon"
                        className="action-edit"
                        title="Edit Device"
                      />
                    </i>
                    <i onClick={() => setDeviceToRemove(node)} className="pl-2" title="remove">
                      <img
                        src="https://cdn.vostro.app/cdn/hotspots/scape/Icon material-delete-forever.svg"
                        alt="remove-icon"
                        className="action-delete"
                        title="Delete Device"
                      />
                    </i>
                  </td>
                </tr>
              )}
            )}
          </tbody>
        </table>
      </Fragment>
    )
};

export default DeviceTable;
