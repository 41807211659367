import React, { Fragment, useState } from "react";
import SpeedGauge from "./speed-gauge";
import {Row, Col } from 'react-bootstrap';
import Button from "../../components/bootstrap/button";
import AnimatedArrow from "../../components/bootstrap/animated-arrow";
import Modal from "../bootstrap/modal";
import "../../style/custom/boost-table.scss";
import Loader from "../bootstrap/loader";
import { useMutation, useQuery } from "@apollo/client";
import { getOperationName } from "apollo-utilities";
import { getProductsQuery, getProductsResult } from "../logic/products";
import { activateAddonsMutation } from "../logic/userplan";
import { getCurrentUserQuery } from "../logic/user";

const BoostTable = (props) => {
    const { activePlan, user } = props;
    const [confirmAddOn, setConfirmAddOn] = useState(false);
    const [confirmButton, setConfirmButton] = useState(false);
    const [isMarked, setIsMarked] = useState(false)
    const productsQuery = useQuery(getProductsQuery);
    const [activateAddon] = useMutation(activateAddonsMutation);
    const hasActivePlan = !!activePlan;

    if (productsQuery.loading) {
        return <Loader />;
    }

    const products = getProductsResult(productsQuery)
    const addonProducts = products.filter(p => p.type === "addon")
    const hasAddon = addonProducts.length > 0

    const availableBoosts = activePlan?.addons?.edges?.map(({node}) => node).find(ao => !ao.alive && ao.rateProfileId) 
    const planBoost = activePlan?.addons?.edges?.map(({node}) => node).find(ao => ao.alive && ao.rateProfileId)

    const handleActivateAddon = async () => {
        await activateAddon({
            variables: {
                addonProductId: addonProducts[0].id,
                userId: user.id
            },
            awaitRefetchQueries: true,
            refetchQueries: [getOperationName(getCurrentUserQuery)]
        })
    };

    const activateBoost = async () => {
        await handleActivateAddon();
        setConfirmAddOn(false);
    };

    const HasBoostLegend = () => {
        return (
            <Fragment>
                <Row className="boost-table-legend-container">
                    <Col lg={12}>
                        <SpeedGauge
                            total={activePlan?.total}
                            usage={activePlan?.usage}
                            unlimited={activePlan?.unlimited}
                            boosted={!!planBoost}
                            hasAvailableBoost={!!availableBoosts}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col 
                        className="boost-button-col"
                        style={{
                            paddingRight: "15px",
                            paddingLeft: "15px",
                         }}
                        xl={4} lg={4} xs={4} sm={4}
                    >
                        <Button
                            variant="no-border"
                            className="boost-button-active pl-3 pr-3"
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                            onClick={() => setConfirmAddOn(true)}
                            disabled={!hasAddon || planBoost || (availableBoosts && !planBoost)}
                            {...(hasAddon && planBoost && {title: "Boost in effect"})}
                            {...(availableBoosts && !planBoost && {title: "Boost enabled. Kindly wait for the boost to take effect"})}
                        >
                            <img 
                                src="https://cdn.vostro.app/cdn/hotspots/scape/btn_boost active.svg"
                                className="boost-button-icon"
                            />  
                            <span className="boost-button-label pl-2 align-middle">Boost</span>
                        </Button>
                    </Col>
                    <Col 
                        className="speed-boost-col pt-2"
                        style={{
                           paddingRight: "0px",
                           paddingLeft: "0px",
                        }}
                        xl={8} lg={8} xs={8} sm={8}
                    >
                        <span className="speed-boost-description" style={{
                            fontFamily: 'Bariol',
                            fontSize: "14px",
                            color: "#242A56"
                        }}>
                            Boosting your internet connection involves improving your data speeds.
                        </span>
                    </Col>
                </Row>
            </Fragment>
        )
    };

    const DisabledBoostLegend = () => {
        return(
            <>
            <Row className="boost-table-none-legend-container">
                <Col lg={12}>
                    <SpeedGauge />
                </Col>
            </Row>
        <   Row>
                <Col 
                    className="boost-button-col"
                    style={{
                        paddingRight: "15px",
                        paddingLeft: "15px",
                     }}
                    xl={4} lg={4} xs={4} sm={4}
                >
                    <Button
                        variant="no-border"
                        className="boost-button pl-3 pr-3"
                        style={{
                            paddingRight: "0px",
                            paddingLeft: "0px",
                         }}
                        disabled={true}
                    >
                        <img 
                            src="https://cdn.vostro.app/cdn/hotspots/scape/btn_boost.svg"
                            className="boost-button-icon"
                        />  
                        <span className="boost-button-label pl-2">Boost</span>
                    </Button>
                </Col>
                <Col 
                    className="speed-boost-col pt-2"
                    style={{
                       paddingRight: "0px",
                       paddingLeft: "0px",
                    }}
                    xl={8} lg={8} xs={8} sm={8}
                >
                    {(!hasActivePlan || !hasAddon)? 
                        <div
                            className="speed-boost-description text-right mr-4 mt-2"
                            style={{
                                fontFamily: 'Bariol',
                                fontSize: "14px",
                                color: "#242A56"
                            }}
                        >
                            Boost Unavailable
                        </div> 
                        : 
                        <div
                          className="speed-boost-description text-justify"
                          style={{
                              fontFamily: 'Bariol',
                              fontSize: "14px",
                              color: "#242A56"
                          }}
                        >
                            Boosting your internet connection involves improving your data speeds.
                        </div>
                    }
                </Col>
            </Row>
        </>
        );
    };

    const NoBoostLegend = () => {
        return (
            <div className="boost-table-none-legend-container">
                <img
                    src="https://cdn.vostro.app/cdn/hotspots/scape/icon_broken lightning.svg"
                    alt="no-boost-icon"
                />
                <div>Boost Not Available</div>
            </div>
        )
    };

    const speedBoostEnableComponent = () => {

        const switchComponent = () => {
            if(isMarked) {
                setConfirmButton(true);
                setIsMarked((prev) => !prev);
            } else {
                setConfirmButton(false);
                setIsMarked((prev) => !prev);
            }
        }

        return(
            <Modal
                title={"Confirm Boost Activation"}
                show
                className="confirm-boost-modal"
                onClose={() => setConfirmAddOn(false)}
                footer={
                    <div className="modal-sb-button">
                        <Button
                            size={"lg"}
                            variant="primary"
                            type="submit"
                            disabled={confirmButton}
                            onClick={() => activateBoost()}
                        >
                            {"ACTIVATE BOOST"}
                        </Button>
                    </div>
                }
            >
                <Row>
                    <Col 
                        className="confirm-speed-boost-modal-content"
                        xl={10} lg={10} md={12}
                    >
                        <span className="modal-boost-description">
                            Boosting your internet connection involves improving your data speed.
                        </span>
                        <p className="modal-boost-question">
                            Are you sure you want to activate Speed Boost?
                        </p>
                        <div 
                            className="custom-control custom-switch"
                        >
                            <input 
                                type="checkbox" 
                                className="custom-control-input speed-boost-input" 
                                id="customSwitch1"
                                checked={isMarked}
                                onChange={() => switchComponent()}
                            />
                            <label 
                                className="custom-control-label speed-boost-label pl-0" for="customSwitch1"
                                id="speed-boost-label"
                            >
                                Yes, I want to enable boost for my internet connection.
                            </label>
                        </div>
                    </Col>
                    <Col 
                        xl={2} lg={2} md={12} sm={0}
                        className="d-none d-lg-block confirm-speed-boost-modal-images d-flex justify-content-center"
                    >
                     
                        {
                            isMarked ?
                                <>
                                    <AnimatedArrow />
                                    <img
                                        src="https://cdn.vostro.app/cdn/hotspots/scape/icon_boost active.svg"
                                        className="modal-boost-icon"
                                        alt="no-boost-icon"
                                    />
                                </>
                               
                            :
                                <>
                                    <AnimatedArrow />
                                    <img
                                        src="https://cdn.vostro.app/cdn/hotspots/scape/icon_boost.svg"
                                        className="modal-boost-icon"
                                        alt="no-boost-icon"
                                    />
                                </>    
                        }
                     
                    </Col>
                </Row>
            </Modal>
        )
    };

    return (
        <>
            {confirmAddOn ?
                speedBoostEnableComponent()
                :
                <></>
            }
            <div className="boost-table-container">
                <div className="boost-table-heading">
                    Speed Boost
                </div>
                {hasAddon ?
                    <div className="boost-table-content">
                        {hasActivePlan ? <HasBoostLegend/> : <DisabledBoostLegend />} 
                    </div>
                    :
                    <div className="boost-table-content d-flex flex-column justify-content-center">
                        <NoBoostLegend/>
                    </div>
                }
            </div>
        </>
    )
}

export default BoostTable;
