import Formsy from "formsy-react";
import React, { useRef } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import TermsAndCondition from "./terms-and-conditions";
import TextField from "../bootstrap/input";
import Button from "../bootstrap/button";
import { acceptTNCMutation, logoutMutation } from "../logic/user";
import { useMutation } from "@apollo/client";
import { localStorage } from "window-or-global";
import { useAsyncSetState } from "use-async-setstate";

const ERROR_CODES = [
  {code: 1, message: "New password cannot be the same as old password"},
  {code: 2, message: "Passwords don't match. Please make sure your password matches"},
  {code: 3, message: "Passwords too short. Please make sure your password is atleast 8 characters long."}
]

const TncWithChangePassword = (props) => {
    const [logout] = useMutation(logoutMutation);
    const [state, setState] = useAsyncSetState({
        processing: false,
        error: undefined,
        error_code: undefined
    });
    const [acceptTNC] = useMutation(acceptTNCMutation);
    const {onClose, user} = props;
    const formRef = useRef();

    const onHandleLogout = async () => {
        await logout();
        localStorage.removeItem("authToken");
        return window.location.href = "/";
    }

    const handleSubmit = async ({ currentPassword, newPassword, confirmPassword }) => {
        try {
          await setState((prevState) => ({ ...prevState, processing: true }));
          let valid = true

          if (currentPassword === newPassword) {
            valid = false;
            
            return setState((prevState) => ({
              ...prevState,
              error: ERROR_CODES[0].message,
              error_code: ERROR_CODES[0].code
            }));
          }

          if (confirmPassword !== newPassword) {
            valid = false;

            return setState((prevState) => ({
              ...prevState,
              error: ERROR_CODES[1].message,
              error_code: ERROR_CODES[1].code
            }));
          }

          if (newPassword.length < 8) {
            valid = false;

            return setState((prevState) => ({
              ...prevState,
              error: ERROR_CODES[2].message,
              error_code: ERROR_CODES[2].code
            }));
          }

          if (valid) {
            const tncAccept = await acceptTNC({
              variables: {
                password: newPassword,
                oldPassword: currentPassword,
              }
            })
    
            if (user?.requireChangePassword) {
              return onHandleLogout();
            }
            await setState((prevState) => ({ ...prevState, processing: false }));
            return onClose();
          } else {
            await setState((prevState) => ({ ...prevState, processing: false }));
          }
        } catch (err) {
          return setState((prevState) => ({
            ...prevState,
            processing: false,
            error: err.message,
            error_code: "invalid"
          }));
        }

        
    }

    const handleAcceptTnc = async () => {
      await setState((prevState) => ({ ...prevState, processing: true }));
      await acceptTNC()
      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    }

    return (
        <Modal
            show
            className="tnc-change-password-container-modal"
            size={(user?.requireAcceptTNC && user?.requireChangePassword) ? "xl" : "lg"}
            centered
            onHide={() => null}
        >
            <Container fluid className="no-gutters">
                <Row className="tnc-change-password-content">
                    {user?.requireAcceptTNC && <Col
                        xs={12}
                        lg={user?.requireChangePassword ? 6 : 12}
                    >
                        <div
                          className={`
                            tnc-change-password-terms
                            ${!user?.requireChangePassword && "border-right-0"}
                            ${!user?.requireChangePassword && "pr-0"}
                          `}
                        >
                          <TermsAndCondition/>
                        </div>
                        {!user?.requireChangePassword && <div className="tnc-terms-buttons-container">
                          <Button
                            variant="no-border"
                            onClick={() => onHandleLogout()}
                            disabled={state.processing}
                          >
                            Logout
                          </Button>
                          <Button
                            onClick={() => handleAcceptTnc()}
                            disabled={state.processing}
                            variant="primary"
                            size="lg"
                          >
                            Accept & Continue
                          </Button>
                        </div>}
                    </Col>}
                    {user?.requireChangePassword && <Col
                        xs={12}
                        lg={user?.requireAcceptTNC ? 6 : 12}
                    >
                        <div className="tnc-changepass-form-container">
                            <p className="tnc-changepass-form-text">
                                For your security, we need you to reset the password on your account.
                            </p>
                            <div>
                            <Formsy
                                ref={formRef}
                                onSubmit={values => handleSubmit(values)}
                                onChange={() => {
                                  if (state.error || state.error_code) {
                                    setState((prevState) => ({
                                      ...prevState,
                                      processing: false,
                                      error: undefined,
                                      error_code: undefined
                                    }));
                                  }
                                }}
                            >
                                <div className="tnc-changepass-form-field-item">
                                    <TextField
                                      className="tnc-changepass-form-field"
                                      label="Current Password"
                                      name="currentPassword"
                                      autoComplete="off"
                                      id="current-password"
                                      required
                                      type="password"
                                      hasError={state.error_code === "invalid"}
                                    />
                                </div>
                                <div className="tnc-changepass-form-field-item">
                                    <TextField
                                      className="tnc-changepass-form-field"
                                      name="newPassword"
                                      autoComplete="off"
                                      id="new-password"
                                      required
                                      type="password"
                                      label="New Password"
                                      hasError={(state.error_code === 1 || state.error_code === 2)}
                                    />
                                </div>
                                <div className="tnc-changepass-form-field-item">
                                    <TextField
                                      label="Confirm Password"
                                      className="tnc-changepass-form-field"
                                      name="confirmPassword"margin-top
                                      autoComplete="off"
                                      id="confirm-password"
                                      required
                                      type="password"
                                      hasError={state.error_code === 2}
                                    />
                                </div>
                                {state.error && (
                                    <div className="custom-error-message">
                                        {state.error}
                                    </div>
                                )}
                                <div className="tnc-changepass-form-buttons-container">
                                    <Button
                                      variant="no-border"
                                      onClick={() => onHandleLogout()}
                                      disabled={state.processing}
                                    >
                                      Logout
                                    </Button>
                                    <Button
                                      type="submit"
                                      disabled={state.processing}
                                      variant="primary"
                                      size="lg"
                                    >
                                      Accept & Continue
                                    </Button>
                                </div>
                            </Formsy>
                            </div>
                        </div>
                    </Col>}
                </Row>
            </Container>
        </Modal>
    )
}

export default TncWithChangePassword;
