import React from "react";
import ProgressSemiCircle from "./progress-semi-circle";

import "../../style/custom/usage-table.scss";

const UsageTable = (props) => {
    const { activePlan, hasActivePlan } = props;

    const HasPlanLegend = () => {
        return (
            <React.Fragment>
                {activePlan?.unlimited ? <div className="text-center pt-4" style={{ fontFamily: 'Bariol Bold' }}>
                    Unlimited
                </div> : 
                <div className="usage-table-legend-container">
                    <div className="usage-table-legend-item">
                        <div className="usage-table-legend-item-color" style={{ background: "#FA70A8 0% 0% no-repeat padding-box" }}/>
                        <div className="usage-table-legend-item-label">
                            Data Usage
                        </div>
                    </div>
                    <div className="usage-table-legend-item">
                        <div className="usage-table-legend-item-color" style={{ background: "#FFCFE3 0% 0% no-repeat padding-box" }}/>
                        <div className="usage-table-legend-item-label">
                            Remaining Data
                        </div>
                    </div>
                </div>}
            </React.Fragment>
        )
    }

    const NoPlanLegend = () => {
        return (
            <div className="usage-table-disconnected-legend-container">
                <div className="usage-table-legend-item">
                    <div className="usage-table-legend-item-color" style={{ background: "#858585 0% 0% no-repeat padding-box" }}/>
                    <div className="usage-table-legend-item-label">
                        No Data Usage
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="usage-table-container">
            <div className="usage-table-heading">
                Usage
            </div>
            <div className="usage-table-content">
                <ProgressSemiCircle
                    total={activePlan?.total}
                    usage={activePlan?.usage}
                    unlimited={activePlan?.unlimited}
                    hasActivePlan={hasActivePlan}
                />
            </div>
            {hasActivePlan ? <HasPlanLegend/> : <NoPlanLegend/>}
        </div>
    )
}

export default UsageTable;
