import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import {getOperationName} from "apollo-utilities";
import "../../../style/custom/update-device.scss";
import TextField from "../../bootstrap/input";
import Modal from "../../bootstrap/modal";
import Button from "../../bootstrap/button";
import {updateDeviceMutation} from "../../logic/device";
import { getCurrentUserQuery } from "../../logic/user";

const UpdateDevice = (props) => {
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
  });

  const { onClose, device } = props;
  const formRef = useRef(null);
  const [updateDevice] = useMutation(updateDeviceMutation);

  const handleSubmit = async ({hostname, macAddress}) => {
    await setState((prevState) => ({ ...prevState, processing: true }));

    try {
      await updateDevice({
        variables: {
          hostname,
          id: device.id
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      })

      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    } catch (err) {
      return setState((prevState) => ({ ...prevState, processing: false, error: err.message || "Something went wrong!" }));
    }
  }

  return (
    <Modal
      className="update-device-modal"
      title={"Update Device"}
      show
      size="md"
      onClose={async() => onClose()}
      footer={
        <div  className="modal-ud-button">
          <Button
            variant="primary"
            type="submit"
            size={"lg"}
            disabled={state.processing}
            onClick={() => formRef.current.submit()}
          >
            Save
          </Button>
        </div>  
      }
    >
      <Formsy ref={formRef} onValidSubmit={handleSubmit}>
        {state.error && <Row>
          <Col>
            <div className="alert alert-danger">
              {state.error}
            </div>
          </Col>
        </Row>}
        <Row className="field-row mb-2">
          <Col>
            <TextField

              label={"Mac Address"}
              placeholder={"Mac Address"}
              defaultValue={device?.macAddress}
              name="macAddress"
              disabled
              required
            />
          </Col>
        </Row>
        <Row className="field-row mb-2">
          <Col>
            <TextField
              label={"Name"}
              name="hostname"
              defaultValue={device?.hostname}
              placeholder={"Name"}
              required
            />
          </Col>
        </Row>
      </Formsy>
    </Modal>
  );
};

export default UpdateDevice;
