import React, { Fragment } from "react";
import moment from "moment-mini";
import Button from "../bootstrap/button";
import planIcon from '../../images/icons/icon_plan.svg'
import "../../style/custom/plan-details.scss";


const PlanDetails = (props) => {
    const { activePlan, user } = props;

    const userPaymentDetails = () => {
      return(
        <div className="plan-details-payment">
          <Button
              variant="link"
              disabled
          >
              <img 
                  src="https://cdn.vostro.app/cdn/hotspots/scape/Icon awesome-credit-card.svg" 
                  alt="cc-icon"
              />
              <span>
                  Stored Payment Details
              </span>
          </Button>
          <Button
              variant="no-border"
              disabled
          >
            <img 
              src="https://cdn.vostro.app/cdn/hotspots/scape/Icon material-update.svg" 
              alt="update-icon"
              style={{
                width: "20px"
              }}
            />
            <span>
              UPDATE
            </span>
          </Button>
        </div>
      );
    };

    return (
        <section className="plan-details-container">
            <div className="plan-details-header">Your Plan Information</div>
            <div className="plan-details-content">
                {activePlan && (
                    <Fragment>
                        <ActivePlan activePlan={activePlan} user={user}/>
                        <Orders
                            activeOrders={user?.activeOrders}
                        />
                    </Fragment>
                )}
                {!activePlan && (
                    <div className="plan-details-no-plan">
                        <img
                            src={planIcon}
                            alt="no-plan-icon"
                        />
                        <div>No Active Plan</div>
                    </div>
                )}
            </div>
        </section>
    )
}

const Orders = ({ activeOrders = [] }) => {
    return activeOrders.map((activeOrder) => {
      return (
        <div key={activeOrder.id} className="active-order-entry-container">
          <OrderItem
            orderItems={activeOrder?.orderItems?.edges}
          />
        </div>
      );
    });
}

const ActivePlan = ({activePlan, user}) => {
    if (!activePlan) {
      return <Fragment/>;
    }
    const product = activePlan?.product;
    const activePlanOrder = user.activeOrders.find(o => o.id === activePlan.order.id)

    return (
      <div className="active-plan-container" key={activePlan.id}>
        {`${product?.description || "No Active Plan"}`}
        <p className="active-order-entry-plan-description">
          {getPlanDescription({
            user,
            product,
            status: activePlanOrder?.status,
            total: (activePlanOrder?.orderItems?.edges || []).reduce((prev, value) => {
              if (!value?.node?.product?.once) {
                return Number(value?.node?.product?.value || 0) + prev;
              }
                
              return prev;
            }, 0),
            activePlan
          })}
        </p>
      </div>
    );
}

const OrderItem = ({orderItems = []}) => {
  const result = orderItems.filter(({node: orderItem}) => !orderItem?.product?.once && orderItem?.product?.type !== "internet")
    .map(({node: orderItem}) => {
      const product = orderItem?.product;
      return (
        <div className="order-item-container" key={orderItem.id}>
          {`${product?.description || "No Active Product"}`}
        </div>
      );
    });

  if (result.length === 0) {
      return null;
  }

  return (
    <div className="order-items-container">
      {result}
    </div>
  );
  }

const getPlanDescription = ({user, product, status, total, activePlan}) => {
  const planEnd = moment(user?.activePlan?.end);
  if (user?.scheduledRenewal) {
    let scheduledProduct = (user?.scheduledRenewal.products || [])[0] || {};
      return `Your plan will change to ${scheduledProduct.description} for $${
      scheduledProduct.value
    } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }
  if (activePlan?.product.id !== product.id) {
    const planEnd = moment(activePlan?.end);
    return `Your plan will change to ${product.description} for $${
      total
    } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }
    switch (status) {
    case undefined:
      return "You do not have an active plan.";
    case 1:
    case 2:
      return "Your plan is inactive";
    case 3:
      return `Your plan will be disabled on the ${planEnd.format(
        "Do of MMMM YYYY"
      )}`;
    case 6:
      return product.description;
    default:
      return `Your next automatic payment will be for $${
        total || 0
      } on ${planEnd.format("Do of MMMM YYYY")}.`;
  }
}

export default PlanDetails;
