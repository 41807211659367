import React, { useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import TextField from "../bootstrap/input";
import Modal from "../bootstrap/modal";
import Button from "../bootstrap/button";
import { logoutMutation, setPasswordMutation } from "../logic/user";
import PropTypes from 'prop-types';

import "../../style/custom/change-password.scss";

const ERROR_CODES = [
  { code: "invalid_password", message: "Old password is incorrect!"},
  { code: "password_not_match", message: "Your passwords don't match. Please enter your password again to confirm it."},
  { code: "password_the_same", message: "New password cannot be the same as old password."},
  { code: "password_too_short", message: "New password must be atleast 8 characters long."}
]

const ChangePassword = (props) => {
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
  });
  const [canSubmit, setCanSubmit] = useAsyncSetState(false)
  const [showPassword, setShowPassword] = useState(false); 
  const { onClose, user } = props;
  const formRef = useRef(null);
  const [logout] = useMutation(logoutMutation);

  const [setPassword] = useMutation(setPasswordMutation);

  const handleSubmit = async ({ password, newpassword, confirmpassword }) => {
    try {
      await setState((prevState) => ({ ...prevState, processing: true }));
      if (newpassword.length < 8) {
        return setState((prevState) => ({
          ...prevState,
          error: ERROR_CODES.find(err => err.code === "password_too_short"),
          processing: false
        }));
      }
      if (confirmpassword !== newpassword) {
        return setState((prevState) => ({
          ...prevState,
          error: ERROR_CODES.find(err => err.code === "password_not_match"),
          processing: false
        }));
      }
      if (password === newpassword) {
        return setState((prevState) => ({
          ...prevState,
          error: ERROR_CODES.find(err => err.code === "password_the_same"),
          processing: false
        }));
      }

      const setPasswordResponse = await setPassword({
        variables: {
          id: user?.id,
          password: newpassword,
          oldPassword: password,
        },
      });
      setState(prevState => ({ ...prevState, processing: false}))
        
      await logout();
      localStorage.removeItem("authToken");
      return window.location.href = "/";
    } catch (err) {
      return setState((prevState) => ({
        ...prevState,
        processing: false,
        error: {
          message: err.message,
          code: "invalid"
        }
      }));
    }
  }

  return (
    <Modal
      title="Change Password"
      className="change-password-modal"
      show
      size="md"
      onClose={async () => onClose()}
      footer={
        <div className="modal-cp-button">
          <Button
            size={"lg"}
            variant="primary"
            type="submit"
            disabled={state.processing || !canSubmit}
            onClick={() => formRef.current.submit()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Formsy
        ref={formRef}
        onValidSubmit={async (data) => handleSubmit(data)}
        onValid={() => setCanSubmit(true)}
        onInvalid={() => setCanSubmit(false)}
        onChange={() => setState(prevState => ({...prevState, error: undefined}))}
      >
        <Row className="field-row username-field mb-2">
          <Col>
            <TextField
              label={"Old Password"}
              name="password"
              placeholder={"Old Password"}
              type={showPassword ? "text" : "password"}
              disabled={state.processing}
              required
              hasError={state.error?.code === "invalid"}
              suffix={
                showPassword ?
                <i
                  onClick={() => setShowPassword(false)} 
                  className="fas fa-eye pass-icon"
                />
                 :
                <i
                  onClick={() => setShowPassword(true)}  
                  className="fas fa-eye-slash pass-icon"
                />
              }
            />
          </Col>
        </Row>
        <Row className="field-row username-field mb-2">
          <Col>
            <TextField
              label={"New Password"}
              name="newpassword"
              placeholder={"New Password"}
              type="password"
              disabled={state.processing}
              required
              hasError={state.error?.code === "password_not_match" || state.error?.code === "password_the_same"}
            />
          </Col>
        </Row>
        <Row className="field-row username-field mb-2">
          <Col>
            <TextField
              label={"Confirm Password"}
              name="confirmpassword"
              placeholder={"Confirm Password"}
              type="password"
              disabled={state.processing}
              required
              hasError={state.error?.code === "password_not_match"}
            />
          </Col>
        </Row>
        {state.error && (
        <Row className="vw-cp-display-messages">
          <Col className="vw-cp-display-container"
          >
            <div className="custom-error-message cp-error">
              {state.error?.message}
            </div>
          </Col>
        </Row>
      )}
      </Formsy>
    </Modal>
  );
};

ChangePassword.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default ChangePassword;
