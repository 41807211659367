import React, { useState } from "react";
import {
  useMutation,
  useQuery,
} from "@apollo/client";
import Main from "../components/main";
import moment from "moment-mini";

import { getCurrentUserQuery, logoutMutation } from "../components/logic/user";
import Loader from "../components/bootstrap/loader";
import Button from "../components/bootstrap/button";
import PortalHeader from "../components/portal/portal-header";

import ChangeDetails from "../components/portal/change-details";
import ChangePassword from "../components/portal/change-password";
import SEO from "../components/section/seo";
import AddDevice from "../components/portal/device/add-device";
import { localStorage } from "window-or-global";
import DataHelper from "../components/utils/data-helper";

import DeviceTable from "../components/portal/device-table";
import UsageTable from "../components/portal/usage-table";
import BoostTable from "../components/portal/boost-table";
import TncWithChangePassword from "../components/portal/tnc-change-password";
import Dropdown from 'react-bootstrap/Dropdown';
import PlanDetails from "../components/portal/plan-details";
import userIcon from "../images/icons/user sillhouette.svg";
import "../style/custom/portal.scss";
import config from '../config';

const Portal = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <Main>
      <SEO title="Scape - Portal" />
      {!sidebarOpen && <PortalHeader
        className="d-laptop-none"
        onClick={() => setSidebarOpen(true)}
      />}
      <PortalIndex
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </Main>
  )
}


const PortalIndex = (props) => {
  const [logout] = useMutation(logoutMutation);
  const currentUserQuery = useQuery(getCurrentUserQuery);
  
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;
  const isPersist = localStorage.getItem("keepUserLogged");
  if (!user && isPersist === "false") {
    return window.location = "/login";
  }

 
  window.onbeforeunload = () => {
    if (isPersist === "false" && !user) {
      logout();
      localStorage.removeItem("authToken");
    }
  };

  return (
    <PortalAccount user={user} {...props} />
  );
};

const PortalAccount = (props) => {
  const { sidebarOpen, setSidebarOpen, user } = props;
  const [closeAcceptTnc, setCloseAcceptTnc] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const openChat = () => {
    // if(!window.groove.widget.shim.isOpen) {
    //   window.groove.widget.init(config.groove, {}); // change grooveid
    // }
    window.groove.widget.open();
  }

  return (
    <div className="portal-container">
      {showChangePassword && <ChangePassword onClose={() => setShowChangePassword(false)} user={user}/>}
      {
        (user?.requireAcceptTNC || user?.requireChangePassword) && // will show if user is required to accept TNC or required to change password
        !closeAcceptTnc &&
        <TncWithChangePassword user={user} onClose={() => setCloseAcceptTnc(true)}/>
      }
      <section className="portal-left-sidebar px-4 d-none d-laptop-flex">
        <a href="https://www.scape.com.au/" target="_blank">
          <img
            src="https://cdn.vostro.app/cdn/hotspots/scape/Scape_Main logo.svg"
            alt="logo"
            className="portal-logo"
          />
        </a>
        <div className="message-us-box">
          <img
            src="https://cdn.vostro.app/cdn/hotspots/scape/icon_bbmessage.svg"
            alt="message-us-icon"
            className="message-us-icon"
          />
          <div className="pb-3 font-weight-bold message-us">
            Chat with us!
          </div>
          <Button variant="primary" onClick={openChat}>
            MESSAGE US
          </Button>
        </div>
      </section>
      <MainContent user={user} sidebarOpen={sidebarOpen}/>
      {!sidebarOpen && (
        <div className="portal-collapsed-menu d-none d-laptop-block">
          <img
            className="portal-collapsed-menu-icon"
            src="https://cdn.vostro.app/cdn/hotspots/scape/icon_burger menu.svg"
            alt="open-sidebar-icon"
            onClick={() => {
              setSidebarOpen(true)
            }}
          />  
        </div>
      )}
      {sidebarOpen && <RightSidebar setSidebarOpen={setSidebarOpen} user={user} setShowChangePassword={setShowChangePassword}/>}
    </div>
  )
}

const MainContent = (props) => {
  const { user, sidebarOpen} = props;
  const { activePlan, firstName, lastName } = user;
  const [showAddDevice, setShowAddDevice] = useState(false);
  console.log(user);
  return (
    <section className={`portal-main d-none ${!sidebarOpen && "d-block"} d-laptop-block`}>
      <div className="text-right pb-4 pb-lg-5 font-weight-bold portal-date">
        {moment().format("DD MMMM YYYY, dddd")}
      </div>
      <div className="portal-banner">
        <div className="portal-banner-text">
          <p className="portal-banner-welcome">
            Welcome {firstName} {lastName}!
          </p>
          <p className="text-justify portal-banner-intro">
            This portal is designed to provide you with the best resources and tools to make your life easier.
          </p>
        </div>
        <img
          src="https://cdn.vostro.app/cdn/hotspots/scape/welcome_illustration.png"
          alt="banner-image"
          className="portal-banner-image d-none d-md-block"
        />
      </div>
      {!activePlan && <p className="portal-no-active-plan">
        <i className="fas fa-info-circle mr-3"/>
        I'm sorry, but it appears that you do not have an active plan, your internet will be disconnected at the end of your current plan.
      </p>}
      <div className="portal-main-first-section">
        <PlanDetails
          activePlan={activePlan}
          user={user}
        />
        <UsageTable
          activePlan={activePlan}
          hasActivePlan={!!activePlan}
        />
        <BoostTable
          activePlan={activePlan}
          user={user}
        />
      </div>
      <div className="portal-table-container">
        <div className="portal-table-devices">
          <div className={`portal-table-devices-label font-weight-bold ${!activePlan && "flex-row-reverse"}`}>
            {activePlan && `Your Devices ${user?.deviceLimit ?  `- ${user?.devices?.edges?.length}/${user?.deviceLimit}` : ""}`}
            <Button
              variant="no-border"
              className="px-3 portal-add-device"
              onClick={() => setShowAddDevice(true)}
              disabled={!activePlan}
            >
              <img 
                 src="https://cdn.vostro.app/cdn/hotspots/scape/icon_add device.svg"
              />  
              ADD DEVICE
            </Button>
          </div>
          <div className="portal-table-devices-container">
            {showAddDevice && <AddDevice user={user} onClose={() => setShowAddDevice(false)} />}
            <DeviceTable
              devices={user?.devices?.edges}
              hasActivePlan={!!activePlan}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const RightSidebar = (props) => {
  const [logout] = useMutation(logoutMutation);
  const { user, setShowChangePassword } = props;
  const isReadOnly = user.readOnly; 
  const [supportID] = (window.atob(user.id) || "").split(":");
  const [detailModal, setDetailModal] = useState(false);
  const [settingsOptions, setSettingsOptions] = useState(false);

  const onHandleLogout = async () => {
    await logout();
    localStorage.removeItem("authToken");
    return window.location.href = "/";
  }

  const userOptions = () => {
    return(
    <div className="user-settings-list">
      <Dropdown>
        {!isReadOnly && <Dropdown.Item onClick={() => setDetailModal(true)} eventKey="1">
            <i className="fa fa-user-check pr-2" aria-hidden="true"/>
            <span className="account-detail-icon">{'Edit Profile'}</span>
          </Dropdown.Item>
        }
        <Dropdown.Item eventKey="2" onClick={() => setShowChangePassword(true)}>
          <i className="fa fa-lock pr-2" aria-hidden="true"/>
          <span className="account-detail-icon">{'Change Password'}</span>
        </Dropdown.Item>
      </Dropdown>
    </div> 
    );
  }; 

  return (
    <section className="portal-right-sidebar">
        <div className="portal-right-sidebar-menu">
          <div
            className=" "
            onClick={() => props.setSidebarOpen(false)}
          >
            <i className="fa fa-times" aria-hidden="true"/>
          </div>
          <button className="portal-logout-button" onClick={() => onHandleLogout()}>
            <span className="portal-logout-label pr-2 align-middle">LOGOUT</span>
            <img
              className="portal-logout-button-icon"
              src="https://cdn.vostro.app/cdn/hotspots/scape/btn logout.svg"
              alt="portal-logout-button-icon"
            />
          </button>
        </div>
        <div className="portal-user-details border-bottom text-center">
          <img
            src={userIcon}
            alt="portal-avatar"
            className="portal-avatar"
          />
          <div className="font-weight-bold portal-user-details-name">{`${user?.firstName} ${user?.lastName}`}</div>
          <div className="portal-user-details-role">{DataHelper.capitalizeFirstLetter(user?.role?.name)}</div>
          <div className="text-left portal-user-details-contacts">
            <img
              src="https://cdn.vostro.app/cdn/hotspots/scape/icon_email.svg"
              alt="user-email-icon"
              className="pr-3"
            />
           {user?.email || "- -"}
          </div>
          <div className="text-left pt-3 portal-user-details-contacts">
            <img
              src="https://cdn.vostro.app/cdn/hotspots/scape/icon_phone.svg"
              alt="user-phone-icon"
              className="pr-3"
            />
            {user?.mobileNumber || "- -"}
          </div>
        </div>
        <div className="portal-account-details">
          <div className="portal-account-details-label">Account Details</div>
          <div className="portal-account-details-contents">
            <div className="portal-account-details-item">
              <div className="portal-account-details-item-label">
                Device Limit
              </div>
              <div className="portal-account-details-item-value">
                {user?.deviceLimit}
              </div>
            </div>
            <div className="portal-account-details-item">
              <div className="portal-account-details-item-label">
                Status
              </div>
              <div className={`portal-account-details-item-value ${user?.disabled ? "text-danger" : "text-success"}`}>
                {user?.disabled ? "Inactive" : "Active"}
              </div> 
            </div>
            <div className="portal-account-details-item">
              <div className="portal-account-details-item-label">
                Support Id
              </div>
              <div className="portal-account-details-item-value">
                {supportID}
              </div>
            </div>
          </div>
        </div>
        <div 
          onMouseLeave={() => setSettingsOptions(false)} 
          className="text-right portal-account-setting pt-5"
        >
          <i 
            onMouseOver={() => setSettingsOptions(true)} 
            className="fa fa-cog pr-2 account-detail-icon" 
            aria-hidden="true"
          />
          <span 
            onMouseOver={() => setSettingsOptions(true)} 
            className="account-settings"
          >
            {'Settings'}
          </span>
          {settingsOptions && userOptions()}
        </div>
        {detailModal && <ChangeDetails user={user} onClose={() => setDetailModal(false)} />}
      </section>
  );
}

export default Portal;
