import React, { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Button from  "../bootstrap/button";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import { getOperationName } from "apollo-utilities";

import TextField from "../bootstrap/input";
import Modal from "../bootstrap/modal";
import { updateUserMutation, getCurrentUserQuery } from "../logic/user";
import "../../style/custom/change-details.scss";

const ChangeDetails = (props) => {
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
    disabled: true
  });
  
  const { onClose, user } = props;
  const formRef = useRef(null);
  const [updateUser] = useMutation(updateUserMutation);

  const handleSubmit = async (model) => {
    await setState((prevState) => ({ ...prevState, processing: true }));

    try {
      await updateUser({
        variables: {
          userId: user.id,
          input: {
            email: model.email,
            mobileNumber: model.mobile,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });

      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    } catch (err) {
      return setState((prevState) => ({
        ...prevState,
        processing: false,
        error: err,
      }));
    }
  }

  return (
    <Modal
      title={"Edit Profile"}
      show
      className="edit-profile-modal"
      onClose={async () => onClose()}
      footer={
          <div className="modal-cd-button">
             <Button
              size={"lg"}
              variant="primary"
              type="submit"
              disabled={state.disabled || state.processing}
              onClick={() => formRef.current.submit()}
            >
              {"Save"}
            </Button>
          </div>
      }
    >
      <Formsy
        ref={formRef} 
        onValidSubmit={handleSubmit}
        onInvalid={()=> setState((prevState) => ({ ...prevState, disabled: true }))}
        onValid={()=> setState((prevState) => ({ ...prevState, disabled: false }))}
      >
        <Row className="field-row mb-2">
          <Col className="custom-field-col-1" xl={6} lg={6} sm={12} xs={12}>
            <TextField
              name="firstName"
              label="First Name"
              defaultValue={user.firstName}
              className="form-disabled"
              placeholder={"First Name"}
              disabled
              testid="firstName"
              required
            />
          </Col>
          <Col className="custom-field-col-2" xl={6} lg={6} sm={12} xs={12}>
            <TextField
              defaultValue={user.lastName}
              name="lastName"
              label="Last Name"
              placeholder={"Last Name"}
              testid="lastName"
              disabled
              required
            />
          </Col>
        </Row>
        <Row className="field-row mb-2">
          <Col className="custom-field-col-1" xl={6} lg={6} sm={12} xs={12}>
            <TextField
              name="email"
              placeholder={"Email"}
              label="Email"
              defaultValue={user.email}
              testid="email"
              required
              validations="isEmail"
              validationErrors={{
                isEmail: "Enter a valid email",
              }}
              disabled={state.processing}
            />
          </Col>
          <Col className="custom-field-col-2" xl={6} lg={6} sm={12} xs={12}>
            <TextField
              name="mobile"
              label="Mobile Number"
              defaultValue={user.mobileNumber}
              placeholder="Mobile Number"
              required
            />
          </Col>
        </Row>
        {state.error && (
          <Row className="vw-cd-display-messages">
            <Col className="vw-cd-display-container cd-error">
              <div className="alert alert-danger cd-error">
                <i
                  className="fas fa-exclamation-circle"
                />
                {state.error}
              </div>
            </Col>
          </Row>
        )}
      </Formsy>
    </Modal>
  );
}

export default ChangeDetails;
