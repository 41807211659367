import React from "react";
import "../../style/custom/speed-gauge.scss"

const AnimatedArrow = () => {
    return(
        <svg className="speed-gauge-boost-icon" xmlns="http://www.w3.org/2000/svg" width="21.403" height="49.168" viewBox="0 0 21.403 49.168">
            <g id="icon_up_triple" data-name="icon_up triple" transform="translate(0 -0.001)">
            <path id="Path_2510" data-name="Path 2510" d="M0,92.347v4.675L10.7,92l10.7,5.023V92.347L10.7,87.325Z" transform="translate(0 -47.854)"/>
            <path id="Path_2509" data-name="Path 2509" d="M0,71.6v4.675l10.7-5.022,10.7,5.022V71.6L10.7,66.576Z" transform="translate(0 -36.483)"/>
            <path id="Path_2511" data-name="Path 2511" d="M10.7,0,0,5.023V30.391L10.7,25.37l10.7,5.022V5.023Z" transform="translate(0)"/>
            </g>
        </svg>
    );
};

export default AnimatedArrow; 