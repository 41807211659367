import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "../../style/custom/speed-gauge.scss"

const SpeedGauge = (props) => {
    const {boosted, hasAvailableBoost} = props;
  
    const AnimatedArrow = () => {
        return (
            <svg className="speed-gauge-boost-icon" xmlns="http://www.w3.org/2000/svg" width="21.403" height="49.168" viewBox="0 0 21.403 49.168">
              <g id="icon_up_triple" data-name="icon_up triple" transform="translate(0 -0.001)">
                <path id="Path_2510" data-name="Path 2510" d="M0,92.347v4.675L10.7,92l10.7,5.023V92.347L10.7,87.325Z" transform="translate(0 -47.854)"/>
                <path id="Path_2509" data-name="Path 2509" d="M0,71.6v4.675l10.7-5.022,10.7,5.022V71.6L10.7,66.576Z" transform="translate(0 -36.483)"/>
                <path id="Path_2511" data-name="Path 2511" d="M10.7,0,0,5.023V30.391L10.7,25.37l10.7,5.022V5.023Z" transform="translate(0)"/>
              </g>
            </svg>
        )
    }

    const AnimatedBars = () => {
        return (
            <svg className="speed-gauge-boost-bars" width="150px" height="150px">
                <path d="M0 75 A 75 75 0 0 1 25 20 Q 32 18 35 25 A 75 75 -180 0 0 10 75 Q 5 78 0 75"/>
                <path d="M150 75 A 75 75 180 0 0 125 20 Q 123 25 115 25A 75 75 180 0 1 140 75 Q 145 82 150 75"/>
                <path d="M27 18 A 75 75 180 0 1 123 18 Q 124 25 113 23 A 75 75 180 0 0 36 24 Q 34 18 27 18"/>
            </svg>
        )
    }

    const BoostUnavailable = () => {
        return(
            <img
                className="disabled-boost-icon"
                src="https://cdn.vostro.app/cdn/hotspots/scape/icon_up disable.svg"
                alt="disabled-boost-icon"
            /> 
        );
    };

    const checkBoostStatus = () => {
        if(boosted) {
            return(
                <AnimatedArrow/>
            )
        } else {
            return(
                <i
                    className="fas fa-spinner fa-pulse boost-load"
                />
            )
        }
    };

    return (
        <div 
            className="speed-gauge-container"
        >
            <div 
                className="gauge-overflow"
            >
                <div className={`gauge-shadow ${(!boosted && !hasAvailableBoost) && "boost-disabled"}`}/>
                <div
                    className="speed-gauge-limit font-weight-bold"
                    style={{
                        fontSize: "20px"
                    }}
                > 
                   {!hasAvailableBoost && !boosted ?
                     BoostUnavailable()  :
                     checkBoostStatus()
                   }
                </div>
                <div
                    className="font-weight-bold speed-gauge-used"
                    style={{
                        opacity: 0.8,
                        color: "#17203F", 
                        fontFamily: "Bariol Bold",
                        fontSize: "21px",
                        lineHeight: 1
                    }}
                >
                    {boosted ? "Boosted" : hasAvailableBoost ? <span style={{ fontSize: "15px" }}>Waiting for boost activation</span> : "Speed Boost"}
                </div>
                <div className="gauge-container">
                    {!boosted ? 
                        <div
                            className={`bar ${(!boosted && !hasAvailableBoost) && "inactive"}`}
                            style={{
                                transform: `rotate(${45 + ((0) * 1.8)}deg)`
                            }}
                        /> :
                        <AnimatedBars/>
                    }
                </div>
            </div>
        </div>
    )
}

SpeedGauge.propTypes = {
    total: PropTypes.number.isRequired,
    usage: PropTypes.number.isRequired,
    unlimited: PropTypes.bool,
    boosted: PropTypes.bool, // sets whether the gauge is boosted or not
    hasAvailableBoost: PropTypes.bool // This states that there are available addon but none of it are alive yet
}

export default SpeedGauge;

