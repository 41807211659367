import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import {getOperationName} from "apollo-utilities";
import "../../../style/custom/add-device.scss";
import TextField from "../../bootstrap/input";
import Modal from "../../bootstrap/modal";
import Button from "../../bootstrap/button";
import {addUserDeviceMutation} from "../../logic/device";
import { getCurrentUserQuery } from "../../logic/user";

const AddDevice = (props) => {
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
    disabled: true
  });

  const { onClose, user } = props;
  const formRef = useRef(null);
  const [addDevice] = useMutation(addUserDeviceMutation);

  const handleSubmit = async ({hostname, macAddress}) => {
    await setState((prevState) => ({ ...prevState, processing: true }));

    try {
      const result = await addDevice({
        variables: {
          hostname,
          macAddress,
          userId: user.id
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      })
      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    } catch (err) {
      return setState((prevState) => ({ ...prevState, processing: false, error: err.message || "Something went wrong!" }));
    }
  }

  return (
    <Modal
      title={"Add Device"}
      className="add-device-modal"
      show
      size="md"
      onClose={async() => onClose()}
      footer={
        <div className="modal-ad-button">
          <Button
            variant="primary"
            size={"lg"}
            type="submit"
            disabled={state.disabled || state.processing}
            onClick={() => formRef.current.submit()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Formsy 
          preventDefaultSubmit
          ref={formRef} 
          onValidSubmit={handleSubmit}
          onInvalid={()=> setState((prevState) => ({ ...prevState, disabled: true }))}
          onValid={()=> setState((prevState) => ({ ...prevState, disabled: false }))}
      >
        <Row className="field-row mb-2">
          <Col>
            <TextField
              label={"Mac Address"}
              name="macAddress"
              required
              placeholder="00:A0:C9:14:C8:29..."
              validations={{
                isMacAddress(_, field) {
                  return /^([0-9A-Fa-f]{2}[:-]?){5}([0-9A-Fa-f]{2})$/.test(field);
                },
              }}
              validationErrors={{
                isMacAddress: "Enter MAC address"
              }}
            />
          </Col>
        </Row>
        <Row className="field-row mb-2">
          <Col>
            <TextField
              label={"Name"}
              name="hostname"
              placeholder={"Name"}
              required
            />
          </Col>
        </Row>
        {state.error && 
          <Row className="vw-ad-display-messages">
            <Col className="vw-ad-display-container"
            >
              <div className="ad-error">
                {state.error}
              </div>
            </Col>
          </Row>
        }
      </Formsy>
    </Modal>
  );
};

export default AddDevice;
