import { gql } from "@apollo/client";

export const activateAddonsMutation = gql`
  mutation activateAddon (
    $addonProductId: String!
    $userId: String!
  ) {
    classMethods {
      UserPlan {
        activateAddon (
          addonProductId: $addonProductId
          userId: $userId
        ) {
          id
          paid
          userId
          orderId
        }
      }
    }
  }
`;