import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import {getOperationName} from "apollo-utilities";
import "../../../style/custom/remove-device.scss";
import Modal from "../../bootstrap/modal";
import Button from "../../bootstrap/button";

import {removeUserDeviceMutation} from "../../logic/device";
import { getCurrentUserQuery } from "../../logic/user";

const ChangeDetails = (props) => {
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
  });

  const { onClose, device } = props;
  const formRef = useRef(null);
  const [removeDevice] = useMutation(removeUserDeviceMutation);

  const handleSubmit = async () => {
    await setState((prevState) => ({ ...prevState, processing: true }));

    try {
      await removeDevice({
        variables: {
          deviceId: device.id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      })

      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    } catch (err) {
      return setState((prevState) => ({ ...prevState, processing: false, error: err }));
    }
  };

  return (
    <Modal
      title={"Remove Device"}
      show
      size="md"
      className="remove-device-modal"
      onClose={async() => onClose()}
      footer={
        <div className="modal-rd-button">
          <Button
            type="submit"
            size={"lg"}
            variant="primary"
            disabled={state.processing}
            onClick={() => formRef.current.submit()}
          >
            Delete
          </Button>
        </div>
      }
    >
      <Formsy ref={formRef} onValidSubmit={handleSubmit}>
        {state.error && <Row>
          <Col>
            <div className="alert alert-danger">
              {state.error}
            </div>
          </Col>
        </Row>}
        <Row>
          <Col>
            <div className="text-center lead">
              <div>
                Delete Device: 
              </div> 
              <span className="pl-2 font-weight-bold">
                  {`${device.hostname || device.macAddress}`}
              </span>
            </div>
          </Col>
        </Row>
      </Formsy>
    </Modal>
  );
};

export default ChangeDetails;
