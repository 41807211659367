import React from "react";
import { Navbar } from "react-bootstrap";
import {
  useMutation,
} from "@apollo/client";
import {logoutMutation} from "../logic/user";
import "../../style/custom/portal-header.scss"

const PortalHeader = (props) => {
  const [logout] = useMutation(logoutMutation);
  const { className, onClick } = props;

  return (
    <header className={`border-bottom portal-header ${className}`}>
      <Navbar expand="laptop">
        <Navbar.Brand href="/">
          <img src="https://cdn.vostro.app/cdn/hotspots/scape/Scape_Main logo.svg" alt="logo" className="logo mt-2" />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => onClick && onClick()}
          aria-controls="basic-navbar-nav"
        >
          <img
            src="https://cdn.vostro.app/cdn/hotspots/scape/icon_burger menu.svg"
            alt="header menu icon"
          />
        </Navbar.Toggle>
      </Navbar>
    </header>
  )
};

export default PortalHeader;
