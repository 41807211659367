import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "../../style/custom/progress-semi-circle.scss"

const ProgressSemiCircle = (props) => {
    console.log(props)
    const {total, usage, unlimited, hasActivePlan} = props;
    const usagePercentage = (!hasActivePlan || total === 0 || total < usage) ? 0 : unlimited ? usage : Math.round((usage/total*100) * 100) / 100 // Usage percentage

    return (
        <div className="progress-semi-circle-container">
            <div className="bar-overflow">
                <div className={`bar-shadow ${!hasActivePlan && "disconnected"}`}/>
                <div
                    className="progress-semi-circle-limit font-weight-bold"
                    style={{
                        fontSize: "20px"
                    }}
                >
                    {!hasActivePlan ? 0 : unlimited ? 
                       <span className="progress-unli"> {usagePercentage}gb</span> : 
                       <span className="progress-unli"> {usagePercentage}% </span>
                    }
                </div>
                <div
                    className="font-weight-bold progress-semi-circle-used"
                    style={{
                        opacity: 0.8,
                        color: "#17203F", 
                        fontFamily: "Bariol Bold",
                        fontSize: "21px"
                    }}
                >
                    Used
                </div>
                <div className="bar-container">
                    <div
                        className={`bar ${!hasActivePlan && "disconnected"}`}
                        style={{
                            transform: `rotate(${45 + ((!hasActivePlan ? 1 : unlimited ? 95 : usagePercentage) * 1.8)}deg)`
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

ProgressSemiCircle.propTypes = {
    total: PropTypes.number.isRequired,
    usage: PropTypes.number.isRequired,
    unlimited: PropTypes.bool,
    hasActivePlan: PropTypes.bool
}

export default ProgressSemiCircle;
